import {useEffect, useState} from "react";
const useGoogleAnalytics = ({ GTAG_ID }: { GTAG_ID: string }) => {
    const [timeChanger, setTimeChanger] = useState<boolean>(false);
    const timer = setTimeout(() => {
        setTimeChanger(true)
    }, 6000)
    useEffect(() => {
        if (timeChanger && GTAG_ID && GTAG_ID !== "0") {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag(...args: [string, Date | string]){window.dataLayer.push(args)}
                gtag('js', new Date());
                gtag('config', GTAG_ID);
            };
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
                clearTimeout(timer)
            };
        }
    }, [GTAG_ID, timeChanger]);
}

export default useGoogleAnalytics;